import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import './css/bootstrap.min.css';
import './css/main.css';

import './css/slick.css';
//import './js/jquery-3.6.4.min.js';
import './js/main.js';
//import './js/gsap/ScrollSmoother.min.js';
//import './js/gsap/ScrollToPlugin.min.js';
//import './js/gsap/ScrollTrigger.min.js';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css.map';
//import 'bootstrap/dist/js/bootstrap.min.js';
//import 'bootstrap/dist/js/bootstrap.bundle.js';
//import 'bootstrap/dist/css/bootstrap-grid.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const axiosBaseURL = axios.create({
  baseURL:'http://clubinweb.in:4000/'
});

export default axiosBaseURL

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();