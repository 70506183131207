import React, { useRef } from "react";
import Slider from "react-slick";


import FeaturedSection from "./FeaturedSection";
import StepsSection from "./StepsSection";
import AppSection from "./AppSection";
import CounterSection from "./CounterSection";
import TestimonialsSection from "./TestimonialsSection";
import FaqAndScreenshotsSection from "./FaqAndScreenshotsSection";
import TeamSection from "./TeamSection";
import ContactAndSubscribeSection from "./ContactAndSubscribeSection";
import MainFooter from "./MainFooter";

import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: require("../images/945x2016.png") },
  { url: require("../images/945x2016-2.png") },
  { url: require("../images/945x2016-3.png") }
];




const HomePage = () => {



  var settings = {
    customPaging: function (i) {

      var returnSlide;
      switch (i) {
        case 1:
          returnSlide = <a>Explorer & Business Profiles</a>
          break;
        case 2:
          returnSlide = <a>QR Code Integration</a>
          break;
        case 3:
          returnSlide = <a>Global Social Engagement</a>
          break;
        default:
          returnSlide = <a>Geolocation-Based Business Discovery</a>
      }
      return (returnSlide);
    },
    dots: true,
    arrows: false,
    infinite: true,
    dotsClass: "slick-dots slick-thumb",
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const SwipeToSlideSettings = {
    arrows: false,
    infinite: true,
    slidesToShow: 6,
    autoplay: true,
    swipeToSlide: true
  };

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const CustomArrowsSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    slidesToScroll: 1
  };

  return (

    <div>

      {/* <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
                <div class="animation-preloader">
                    <div class="spinner"></div>
               
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </div> */}

      {/* ==== header start ==== */}
      <header class="header" style={{ position: `sticky`, top: `0` }}>
        <div class="container" >
          <div class="row" >
            <div class="col-lg-12">
              <nav class="nav" >
                <div class="nav__content" >
                  <div class="nav__logo">
                    <a href="/">
                      <img src={require('../images/logo.svg').default} alt="" />
                    </a>

                  </div>
                  <div class="nav__menu">
                    <div class="nav__menu-logo d-flex d-xl-none">
                      <a href="/" class="text-center hide-nav">

                        <img src={require('../images/logo.svg').default} alt="" />
                      </a>
                      <a href="javascript:void(0)" class="nav__menu-close">
                        <i class="fa-solid fa-xmark"></i>
                      </a>
                    </div>
                    <ul class="nav__menu-items">
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">ABOUT US</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">HOW WE WORK</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">KEY FEATURES</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/PrivacyPolicy">TRUST & SAFETY</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">OUR TEAM</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">CONTACT US</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/postspage">POSTS</a>
                      </li>
                    </ul>

                  </div>
                  <div class="nav__uncollapsed">
                    <div class="nav__uncollapsed-item d-none d-md-flex">
                      <a href="#" class=" "><img src={require('../images/playstore.svg').default} alt="Cling Play Store" /></a>
                    </div>
                    <button class="nav__bar d-block d-xl-none">
                      <span class="icon-bar top-bar"></span>
                      <span class="icon-bar middle-bar"></span>
                      <span class="icon-bar bottom-bar"></span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="backdrop"></div>
      </header>


      <div id="smooth-wrapper"  >
        <div id="smooth-content" style={{ marginTop: `-105px` }}>

          {/* <!-- ==== banner ssection start ==== --> */}
          <section class="banner bg-pink gsap-fix-top" >

            <section class="slick-section slick-wrap custome-dots" style={{ zIndex: '1' }}>

              <div class="slick-container" style={{ height: `420px` }}>



                <Slider {...settings}  >

                  <div class="slick-item">
                    <div class="slide-item" >
                      <div class="container">
                        <div class="row align-items-center">
                          <div class="col-12 col-lg-8 col-xxl-6">
                            <h1>Explore & Engage with Your Community on Cling</h1>
                            <h3>Your Go-To App for Exploring Nearby Shops, Restaurants, and Services</h3>
                            <a href="#" class="btn btn--primary">DOWNLOAD NOW</a>
                          </div>
                          <div class="col-12 col-lg-4 col-xxl-6">
                            <img src={require('../images/hero-banner.svg').default} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="slick-item">
                    <div class="slide-item">
                      <div class="container">
                        <div class="row align-items-center">
                          <div class="col-12 col-lg-8 col-xxl-6">
                            <h3>Slide 2</h3>
                            <h3>Slide 2 Sub Heading Text</h3>
                            <a href="#" class="btn btn--primary">DOWNLOAD NOW</a>

                          </div>
                          <div class="col-12 col-lg-4 col-xxl-6">
                            <img src={require('../images/hero-banner.svg').default} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="slick-item">
                    <div class="slide-item">
                      <div class="container">
                        <div class="row align-items-center">
                          <div class="col-12 col-lg-8 col-xxl-6">
                            <h1>Slide 3</h1>
                            <h3>Slide 3 Sub Heading Text</h3>
                            <a href="#" class="btn btn--primary">DOWNLOAD NOW</a>

                          </div>
                          <div class="col-12 col-lg-4 col-xxl-6">
                            <img src={require('../images/hero-banner.svg').default} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="slick-item">
                    <div class="slide-item">
                      <div class="container">
                        <div class="row align-items-center">
                          <div class="col-12 col-lg-8 col-xxl-6">
                            <h1>Slide 4</h1>
                            <h3>Slide 4 Sub Heading Text</h3>
                            <a href="#" class="btn btn--primary">DOWNLOAD NOW</a>

                          </div>
                          <div class="col-12 col-lg-4 col-xxl-6">
                            <img src={require('../images/hero-banner.svg').default} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </Slider>


              </div>


            </section>
          </section>

          {/* <!-- ==== / banner ssection end ==== --> */}


          {/* <!-- ==== about section start ==== --> */}
          <section class="section bg-white about-section">
            <div class="container">
              <div class="row gaper align-items-center">
                <div class="col-12 col-lg-5 col-xl-5">
                  <div class="about-section__thumb wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="600ms">

                    <img src={require('../images/whoweare.svg').default} alt="" />
                  </div>
                </div>
                <div class="col-12 col-lg-7 col-xl-7">
                  <div class="about-section__content section__content">
                    <p class="h2 sub-title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                      Who We Are?
                    </p>
                    <h2 class="h4 mb-16 title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">Cling is your ultimate companion for exploring the best of your local community.
                    </h2>
                    <div class="paragraph wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">
                      <p class="fw-5 "> Whether you're searching for a cozy cafe, trendy boutique, or hidden gem,
                        Cling helps you discover and connect with nearby businesses, all at your fingertips.</p>

                      <p>Cling drives the discovery of brands and retailers across categories like fashion, food, electronics, grocery, pharmacy, home delivery, spa, nightlife, entertainment and more. It builds engagement and visibility for retailers and brands to help boost demand and offers an omni channel platform to connect with relevant local customers.
                      </p>
                    </div>
                    <div class="cta__group justify-content-start wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ==== / about section end ==== --> */}



          {/* <!-- ==== sponsor section start ==== --> */}
          <div class="sponsor section bg-grey">
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-12">
                  <h3 class="h4">Our Top Clients</h3>
                  <div class="sponsor__slider wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                    <Slider {...SwipeToSlideSettings}>
                      <div class="sponsor__slider-item">

                        <img src={require('../images/sponsor/kfc.svg').default} alt="KFC" />
                      </div>
                      <div class="sponsor__slider-item">

                        <img src={require('../images/sponsor/client-2.svg').default} alt="" />
                      </div>
                      <div class="sponsor__slider-item">

                        <img src={require('../images/sponsor/three.svg').default} alt="" />
                      </div>
                      <div class="sponsor__slider-item">

                        <img src={require('../images/sponsor/four.svg').default} alt="" />
                      </div>
                      <div class="sponsor__slider-item">
                        <img src={require('../images/sponsor/five.svg').default} alt="" />

                      </div>
                      <div class="sponsor__slider-item">
                        <img src={require('../images/sponsor/six.svg').default} alt="" />

                      </div>
                      <div class="sponsor__slider-item">
                        <img src={require('../images/sponsor/seven.svg').default} alt="" />

                      </div>
                      <div class="sponsor__slider-item">
                        <img src={require('../images/sponsor/eight.svg').default} alt="" />

                      </div>
                      <div class="sponsor__slider-item">
                        <img src={require('../images/sponsor/nine.svg').default} alt="" />

                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- ==== / sponsor section end ==== --> */}

          {/* <!-- ==== work section start ==== --> */}
          <section class="work-section section pt-0">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="work-section__inner">
                    <div class="row gaper align-items-center">
                      <div class="col-12 col-lg-7 col-xxl-7">
                        <div class="work-section__content">
                          <div class="section__header">
                            <h2 class="h3 sub-title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">How Cling Works?</h2>
                          </div>
                          <ul>
                            <li class="wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                              <div class="thumb">
                                <span>01</span>
                              </div>
                              <div class="item-text"><h3 class="h5">Discover</h3>
                                <p>Use our location-based services to find businesses near you.</p>
                              </div>
                            </li>
                            <li class="wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                              <div class="thumb">
                                <span>02</span>
                              </div>
                              <div class="item-text"><h3 class="h5">Engage</h3>
                                <p>Rate and review your experiences to help others make informed decisions.</p>
                              </div>
                            </li>
                            <li class="wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">
                              <div class="thumb">
                                <span>03</span>
                              </div>
                              <div class="item-text"><h3 class="h5">Connect</h3>
                                <p>Share your favourite spots with friends and fellow CLING users.</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-12 col-lg-5 col-xxl-4 offset-xxl-1">
                        <div class="work-section__thumb wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">
                          <img src={require('../images/cling-Works.svg').default} alt="" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ==== / work section end ==== --> */}

          {/* <!-- ==== choose section start ==== --> */}
          <section class="section  bg-grey">
            <div class="container">
              <div class="row gaper align-items-center">

                <div class="col-12 col-lg-7 col-xxl-5">
                  <div class="section__content choose-section__content">

                    <h2 class="h3 sub-title mb-16 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">Accelerate business growth with Cling App
                    </h2>
                    <ul>
                      <li class="wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">

                        <div class="content">
                          <p class="h5 mb-16">The biggest online local business app in the social business industry in India</p>
                          <p>Enjoy industry-best success rates and uncompromised security
                            Integrate with a no-code setup, developer-friendly APIs & native SDK. Handle large-scale transactions with dynamic routing ensuring 100% uptime</p>
                          <div class="cta__group justify-content-start wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <a href="#" class="btn btn--primary">Start Business Account </a>

                          </div>
                        </div>
                      </li>

                    </ul>

                  </div>
                </div>
                <div class="col-12 col-lg-5">
                  <img src={require('../images/appbusiness.svg').default} alt="" />

                </div>
              </div>
            </div>
          </section>
          {/* <!-- ==== / choose section end ==== --> */}


          {/* <!-- ==== Features section start ==== --> */}
          <section class="section bg-white features-section">
            <div class="container">
              <div class="row gaper align-items-center">
                <div class="col-12 col-lg-5 col-xl-5">
                  <div class="about-section__thumb wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="600ms">
                    <img src={require('../images/feature.svg').default} alt="" />

                  </div>
                </div>
                <div class="col-12 col-lg-7 col-xl-7">
                  <div class="about-section__content section__content">
                    <p class="h2 sub-title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                      Our Key Features?
                    </p>
                    <h2 class="h5  title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">For Explorers</h2>
                    <div class="ul wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">
                      <ul>
                        <li>Find hidden treasures and popular hotspots in your neighborhood.    </li>
                        <li>Unlock exclusive discounts and promotions from participating businesses.</li>
                        <li>Share your adventures and connect with like-minded locals.</li>
                      </ul>
                    </div>
                    <h2 class="h5  title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">For Businesses</h2>
                    <div class="ul wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">
                      <ul>
                        <li>Boost visibility and attract new customers to your doorstep. </li>
                        <li>Build trust with authentic reviews and positive ratings from satisfied customers.</li>
                        <li>Increase customer loyalty by offering special deals and rewards.</li>
                      </ul>
                    </div>
                    <div class="cta__group justify-content-start wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ==== / Features section end ==== --> */}

          {/* <!-- ==== testimonial section start ==== --> */}
          <section class="section testimonial bg-pink" style={{ height: `481px` }}>
            <div class="container">
              <div class="row gaper">
                <div class="col-12 col-xl-4">
                  <div class="section__content testimonial__content">
                    <p class="h6 sub-title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                      Customer testimonial
                    </p>
                    <h2 class="h2 title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">They
                      love us. You
                      will too.
                    </h2>
                    <div class="paragraph wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">
                      <p>Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generat
                      </p>
                    </div>
                    <div class="testimonial__content-cta section__content-cta">
                      <button aria-label="previous item" class="slide-btn prev-testimonial" onClick={previous}>
                        <i class="icon-arrow-left"></i>
                      </button>
                      <button aria-label="next item" class="slide-btn next-testimonial" onClick={next}>
                        <i class="icon-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-8">
                  <div class="testimonial__slider">

                    <Slider ref={slider => {
                      sliderRef = slider;
                    }} {...CustomArrowsSettings}>
                      <div class="testimonial__slider-item">
                        <div class="quote">
                          <i class="icon-quote"></i>
                        </div>
                        <div class="content">
                          <q class="h4">I love how Cling helps me discover new places right in my own neighborhood!</q>
                        </div>
                        <hr />
                        <div class="item__meta">
                          <div class="meta__thumb">
                            <img src={require('../images/author-one.png')} alt="" />

                          </div>
                          <div class="meta__content">
                            <p class="h5">Deepthi Priya</p>
                            <p>Hyderabad</p>
                          </div>
                        </div>
                      </div>

                      <div class="testimonial__slider-item">
                        <div class="quote">
                          <i class="icon-quote"></i>
                        </div>
                        <div class="content">
                          <q class="h4">Cling has been a game-changer for my small business. We've seen a noticeable increase in foot traffic and sales!</q>
                        </div>
                        <hr />
                        <div class="item__meta">
                          <div class="meta__thumb">
                            <img src={require('../images/author-two.png')} alt="" />

                          </div>
                          <div class="meta__content">
                            <p class="h5">Kapil Varma</p>
                            <p>Bangalore</p>
                          </div>
                        </div>
                      </div>

                      <div class="testimonial__slider-item">
                        <div class="quote">
                          <i class="icon-quote"></i>
                        </div>
                        <div class="content">
                          <q class="h4">Clingrr is like having a local guide in my pocket! I've found so many hidden gems thanks to this app.</q>
                        </div>
                        <hr />
                        <div class="item__meta">
                          <div class="meta__thumb">
                            <img src={require('../images/author-three.png')} alt="" />

                          </div>
                          <div class="meta__content">
                            <p class="h5">Dinesh Shah</p>
                            <p>Vizag</p>
                          </div>
                        </div>
                      </div>

                      <div class="testimonial__slider-item">
                        <div class="quote">
                          <i class="icon-quote"></i>
                        </div>
                        <div class="content">
                          <q class="h4">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.
                          </q>
                        </div>
                        <hr />
                        <div class="item__meta">
                          <div class="meta__thumb">
                            <img src={require('../images/author-three.png')} alt="" />

                          </div>
                          <div class="meta__content">
                            <p class="h5">Delores Olivo</p>
                            <p>Content & Marketing Coordinator</p>
                          </div>
                        </div>
                      </div>

                    </Slider>

                  </div>
                </div>
              </div>
            </div>

          </section>
          {/* <!-- ==== / testimonial section end ==== --> */}


          {/* <!-- Privacy Matters --> */}
          <section class="section privacy-section bg-white">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-10 col-lg-8">
                  <div class="section__header text-center">
                    <p class="h2 sub-title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                      Your Privacy Matters to Us
                    </p>

                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-4"> <img src={require('../images/security.svg').default} alt="" />
                  <h4 cl>What Information We Collect</h4>
                  <p>Location data to show nearby businesses and enhance your user experience. Interaction data such as reviews, ratings, and social interactions within the app.</p></div>
                <div class="col-xl-4"><img src={require('../images/security-1.svg').default} alt="" />
                  <h4>How We Use Your Information</h4>
                  <p>Provide and improve our services, including recommending businesses based on your preferences.Ensure the security and integrity of our platform and protect against misuse.</p></div>

                <div class="col-xl-4"><img src={require('../images/security-2.svg').default} alt="" />
                  <h4>How We Use Your Information</h4>
                  <p>Provide and improve our services, including recommending businesses based on your preferences.Ensure the security and integrity of our platform and protect against misuse.</p></div>

              </div>
            </div>
          </section>


          {/* <!-- End Privacy Matters --> */}

          <section class="section appinfo-section bg-pink">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-4">
                  <h3>2.5+ Crores</h3>
                  <h6>App Downloads</h6>
                </div>
                <div class="col-xl-4">
                  <h3>India’s Best</h3>
                  <h6>Trusted pan-india</h6>
                </div>

                <div class="col-xl-4">
                  <h3>1.5 Crore</h3>
                  <h6>Verified Biz. Reviews</h6>
                </div>

              </div>
            </div>
          </section>


          {/* <!-- ==== services section start ==== --> */}
          <section class="section services ">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-10 col-lg-8">
                  <div class="section__header text-center">
                    <p class="h2 sub-title wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                      Our Team
                    </p>

                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-3 ">
                  <div class="team-list">
                    <div class="team-avatar"> <img src={require('../images/author-one.png')} alt="" /> </div>
                    <h5>Sesu Gonella</h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>

                <div class="col-3 ">
                  <div class="team-list">
                    <div class="team-avatar">  <img src={require('../images/author-two.png')} alt="" /> </div>
                    <h5>Sesu Gonella</h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>

                <div class="col-3 ">
                  <div class="team-list">
                    <div class="team-avatar"> <img src={require('../images/author-three.png')} alt="" /> </div>
                    <h5>Sesu Gonella</h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
                <div class="col-3 ">
                  <div class="team-list">
                    <div class="team-avatar"> <img src={require('../images/author-one.png')} alt="" /> </div>
                    <h5>Sesu Gonella</h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
                <div class="col-3 ">
                  <div class="team-list">
                    <div class="team-avatar"> <img src={require('../images/author-two.png')} alt="" /> </div>
                    <h5>Sesu Gonella</h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
                <div class="col-3 ">
                  <div class="team-list">
                    <div class="team-avatar"> <img src={require('../images/author-three.png')} alt="" /> </div>
                    <h5>Sesu Gonella</h5>
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ==== / services section end ==== --> */}


          {/* <!-- ==== footer section start ==== --> */}
          <footer class="section section--space-top footer bg-pink gsap-fix-bottom">
            <div class="container">
              <div class="row gaper">
                <div class="col-12 col-sm-6 col-lg-2 col-xl-2">
                  <div class="footer__single wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                    <div class="logo">
                      <a href="index.html">
                        <img src={require('../images/log-bw.svg').default} alt="" />

                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-8 col-xl-8">
                  <div class="footer__single wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="900ms">

                    <div class="footer__list">
                      <ul>
                        <li><a href="#">ABOUT US</a>
                        </li>
                        <li><a href="#">FEATURES</a>
                        </li>
                        <li><a href="#">CAREERS</a></li>
                        <li><a href="#">BLOGS</a></li>
                        <li><a href="#">CONTACT US</a></li>
                        <li><a href="#">TRUST & SAFETY</a></li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-lg-2 col-xl-2">
                  <div class="footer__single wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="1200ms">
                    <a href="#" class=" "><img src={require('../images/playstore.svg').default} alt="" /></a>
                  </div>
                </div>
              </div>

            </div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="footer__bottom">
                    <div class="row align-items-center">
                      <div class="col-12 ">
                        <ul class="social">
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a href="index.html" aria-label="social media">
                              <i class="fa-brands fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12  ">
                        <div class="footer__copy text-center ">
                          <p>Copyright &copy; <span id="copyYear"></span> <a href="index.html">Cling.</a>
                            All Rights Reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/* <!-- ==== / footer section end ==== --> */}



        </div>
      </div>

      {/* <!-- scroll to top --> */}

      <div class="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>


      {/* <FeaturedSection/>
      <StepsSection/>
      <AppSection/>
      <CounterSection/>
      <TestimonialsSection/>
      <FaqAndScreenshotsSection/>
      <TeamSection/>
      <ContactAndSubscribeSection/>
      <MainFooter/>       */}

    </div>
  );
};

export default HomePage;
