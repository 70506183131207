import React from "react";
import '../css/privacy-policy.css';


const PrivacyPolicy = () => {

return (
  <>
  
    {/* ==== header start ==== */}
    <header class="header" style={{position: `sticky`, top: `0`}}>
        <div class="container" >
          <div class="row" >
            <div class="col-lg-12">
              <nav class="nav" >
                <div class="nav__content" >
                  <div class="nav__logo">
                    <a href="/">
                      <img src={require('../images/logo.svg').default} alt="" />
                    </a>

                  </div>
                  <div class="nav__menu">
                    <div class="nav__menu-logo d-flex d-xl-none">
                      <a href="/" class="text-center hide-nav">

                        <img src={require('../images/logo.svg').default} alt="" />
                      </a>
                      <a href="/" class="nav__menu-close">
                        <i class="fa-solid fa-xmark"></i>
                      </a>
                    </div>
                    <ul class="nav__menu-items">
                    <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">HOME</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">ABOUT US</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">HOW WE WORK</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">KEY FEATURES</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">OUR TEAM</a>
                      </li>

                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/">CONTACT US</a>
                      </li>
                      <li class="nav__menu-item">
                        <a class="nav__menu-link hide-nav" href="/postspage">POSTS</a>
                      </li>
                    </ul>

                  </div>
                  <div class="nav__uncollapsed">
                    <div class="nav__uncollapsed-item d-none d-md-flex">
                      <a href="/" class=" "><img src={require('../images/playstore.svg').default} alt="Cling Play Store" /></a>
                    </div>
                    <button class="nav__bar d-block d-xl-none">
                      <span class="icon-bar top-bar"></span>
                      <span class="icon-bar middle-bar"></span>
                      <span class="icon-bar bottom-bar"></span>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="backdrop"></div>
      </header>

    <div class="row " style={{padding:`1% 13%`}}>
      <p class='class-one'><strong><span style={{ fontSize: `21px` }} >PRIVACY NOTICE</span></strong></p>
      <p class='class-one'>Last updated August 25, 2022</p>
      <p class='class-one'>&nbsp;</p>
      <p class='class-one'><span class='font-size-14'>This privacy notice for CLUBIN PLATFORMS PRIVATE LIMITED (&apos;<strong>Company</strong>&apos;, &apos;<strong>we</strong>&apos;, &apos;<strong>us</strong>&apos;, or &apos;<strong>our</strong>&apos;), describes how and why we might collect, store, use, and/or share (&apos;<strong>process</strong>&apos;) your information when you use our services (&apos;<strong>Services</strong>&apos;), such as when you:</span></p>
      <p class='class-one'><span class='font-size-14'>&nbsp;</span></p>
      <ul class='class-five'>
        <li><span class='font-size-14'>Download and use our mobile application (Clubln), or any other application of ours that links to this privacy notice</span></li>
        <li><span class='font-size-14'>Engage with us in other related ways, including any sales, marketing, or events</span></li>
      </ul>
      <p class='class-two'><span class='font-size-14'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-14'>Questions or concerns?</span></strong><span class='font-size-14'>&nbsp;Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at&nbsp;</span><a href="mailto:admin@clubinweb.in"><span class='font-size-13'>admin@clubinweb.in</span></a><span class='font-size-13'>.</span></p>
      <p class='class-one'>&nbsp;</p>
      <p class='class-one'><strong><span class='font-size-16'>SUMMARY OF KEY POINTS</span></strong></p>
      <p class='class-one'>&nbsp;</p>
      <p class='class-one'><strong><em><span class='font-size-13'>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click <span style={{ color: `#0070C0` }}>here</span> to go directly to our table of contents.</span></em></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>What personal information do we process?</span></strong><span class='font-size-13'>&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with CLUBIN PLATFORMS PRIVATE LIMITED and the Services, the choices you make, and the products and features you use. Click <span style={{ color: `#0070C0` }}>here</span> to learn more</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Do we process any sensitive personal information?</span></strong><span class='font-size-13'>&nbsp;We do not process sensitive personal information.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Do we receive an information from third parties?</span></strong><span class='font-size-13'>&nbsp;We do not receive an information from third parties.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>How do we process your information?</span></strong><span class='font-size-13'>&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click <span style={{ color: `#0070C0` }}>here</span> to learn more.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>In what situations and with which parties do we share personal information?</span></strong><span class='font-size-13'>&nbsp;We may share information in specific situations and with specific third parties. Click <span style={{ color: `#0070C0` }}>here</span> to learn more.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>How do we keep your information safe?</span></strong><span class='font-size-13'>&nbsp;We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click <span style={{ color: `#0070C0` }}>here</span> to learn more.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>What are your rights?</span></strong><span class='font-size-13'>&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click <span style={{ color: `#0070C0` }}>here</span> to learn more.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>How do you exercise your rights?</span></strong><span class='font-size-13'>&nbsp;The easiest way to exercise your rights is by filling out our <span style={{ color: `#0070C0` }}>data subject request form available here</span>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>Want to learn more about what CLUBIN PLATFORMS PRIVATE LIMITED does with any information we collect?</span></p>
      <p class='class-one'><span class='font-size-13'>Click <span style={{ color: `#0070C0` }}>here</span> to review the notice in full</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>TABLE OF CONTENTS</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>1. WHAT INFORMATION DO WE COLLECT?</span></p>
      <p class='class-one'><span class='font-size-13'>2. HOW DO WE PROCESS YOUR INFORMATION?</span></p>
      <p class='class-one'><span class='font-size-13'>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></p>
      <p class='class-one'><span class='font-size-13'>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></p>
      <p class='class-one'><span class='font-size-13'>5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></p>
      <p class='class-one'><span class='font-size-13'>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p>
      <p class='class-one'><span class='font-size-13'>7. WHAT ARE YOUR PRIVACY RIGHTS?</span></p>
      <p class='class-one'><span class='font-size-13'>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p>
      <p class='class-one'><span class='font-size-13'>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></p>
      <p class='class-one'><span class='font-size-13'>10. DO WE MAKE UPDATES TO THIS NOTICE?</span></p>
      <p class='class-one'><span class='font-size-13'>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></p>
      <p class='class-one'><span class='font-size-13'>12. HOW CAN YOU REVIEW. UPDATE. OR DELETE THE DATA WE COLLECT FROM YOU?</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>1. WHAT INFORMATION DO WE COLLECT?</span></strong></p>
      <p class='class-one'><strong><span class='font-size-16'>&nbsp;</span></strong></p>
      <p class='class-one'><strong>Personal information you disclose to us</strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;We collect personal information that you provide to us.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Personal Information Provided by You.</span></strong><span class='font-size-13'>&nbsp;The personal information that we collect depends on the context of your</span></p>
      <p class='class-one'><span class='font-size-13'>interactions with us and the Services, the choices you make, and the products and features you use. The personal</span></p>
      <p class='class-one'><span class='font-size-13'>information we collect may include the following:</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <ul class='class-five'>
        <li><span class='font-size-13'>Names</span></li>
        <li><span class='font-size-13'>phone numbers</span></li>
        <li><span class='font-size-13'>email addresses</span></li>
        <li><span class='font-size-13'>passwords</span></li>
        <li><span class='font-size-13'>contact or authentication data</span></li>
        <li><span class='font-size-13'>contact preferences</span></li>
        <li><span class='font-size-13'>date of birth</span></li>
      </ul>
      <p class='class-two'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Sensitive Information.</span></strong><span class='font-size-13'>&nbsp;We do not process sensitive information.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Application Data.</span></strong><span class='font-size-13'>&nbsp;If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><em><span class='font-size-13'>Geolocation Information.</span></em><span class='font-size-13'>&nbsp;We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&apos;s settings.</span></li>
        </ul>
      </div>
      <p class='class-two'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><em><span class='font-size-13'>Mobile Device Access.</span></em><span class='font-size-13'>&nbsp;We may request access or permission to certain features from your mobile device, including your mobile device&apos;s camera, and other features. If you wish to change our access or permissions, you may do so in your device&apos;s settings.</span></li>
        </ul>
      </div>
      <p class='class-two'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><em><span class='font-size-13'>Push Notifications.</span></em><span class='font-size-13'>&nbsp;We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device&apos;s settings.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></strong></p>
      <p class='class-one'><strong><span class='font-size-13'>&nbsp;</span></strong></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To facilitate account creation and authentication and otherwise manage user accounts.</span></strong><span class='font-size-13'>&nbsp;We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To deliver and facilitate delivery of services to the user.</span></strong><span class='font-size-13'>&nbsp;We may process your information to provide you with the requested service</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To respond to user inquiries/offer support to users.&nbsp;</span></strong><span class='font-size-13'>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To send administrative information to you.</span></strong><span class='font-size-13'>&nbsp;We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To fulfil and manage your orders.</span></strong><span>&nbsp;We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To enable user-to-user communications.&nbsp;</span></strong><span class='font-size-13'>We may process your information if you choose to use any of our offerings that allow for communication with another user.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To request feedback.</span></strong><span class='font-size-13'>&nbsp;We may process your information when necessary to request feedback and to contact you about your use of our Services.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To deliver targeted advertising to you.</span></strong><span class='font-size-13'>&nbsp;We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To post testimonials.</span></strong><span class='font-size-13'>&nbsp;We post testimonials on our Services that may contain personal information.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To protect our Services</span></strong><span class='font-size-13'>. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To evaluate and improve our Services, products, marketing, and your experience.</span></strong><span class='font-size-13'>&nbsp;We may process your</span></li>
        </ul>
      </div>
      <p class='class-four'><span class='font-size-13'>information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To identify usage trends.&nbsp;</span></strong><span class='font-size-13'>We may process information about how you use our Services to better understand how they are being used so we can improve them.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To determine the effectiveness of our marketing and promotional campaigns.&nbsp;</span></strong><span class='font-size-13'>We may process your</span></li>
        </ul>
      </div>
      <p class='class-four'><span class='font-size-13'>information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <div class='class-one'>
        <ul class='class-three'>
          <li class='class-one'><strong><span class='font-size-13'>To comply with our legal obligations.</span></strong><span >&nbsp;We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</span></li>
        </ul>
      </div>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;We may share information in specific situations described in this section and/or with the following third parties.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>We may need to share your personal information in the following situations:</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Business Transfers.</span></strong><span class='font-size-13'>&nbsp;We may share or transfer your information in connection with, or during negotiations of, any</span></p>
      <p class='class-one'><span class='font-size-13'>merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-13'>Other Users.&nbsp;</span></strong><span class='font-size-13'>When you share personal information (for example, by posting comments, contributions, or other</span></p>
      <p class='class-one'><span class='font-size-13'>content to the Services) or otherwise interact with public areas of the Services, such personal information may be</span></p>
      <p class='class-one'><span class='font-size-13'>viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view our profile.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>When we have no ongoing legitimate business need to process your personal information, we will either delete or</span></p>
      <p class='class-one'><span class='font-size-13'>anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;We aim to protect your personal information through a system of organisational and technical security measures.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>7. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></p>
      <p class='class-one'><strong><span class='font-size-16'>&nbsp;</span></strong></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;You may review, change, or terminate your account at any time.</span></em></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><u><span class='font-size-13'>Withdrawing your consent:</span></u></strong><span class='font-size-13'>&nbsp;If we are relying on your consent to process your personal information, which may be</span></p>
      <p class='class-one'><span class='font-size-13'>express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section &apos;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&apos; below.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong>Account Information</strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>If you would at any time like to review or change the information in your account or terminate your account, you can:</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <ul class='class-five'>
        <li><span class='font-size-13'>Log in to your account settings and update your user account.</span></li>
        <li><span class='font-size-13'>Contact us using the contact information provided.</span></li>
      </ul>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>If you have questions or comments about your privacy rights, you may email us at&nbsp;</span><a href="mailto:admin@clubinweb.in"><span class='font-size-13'>admin@clubinweb.in</span></a><span class='font-size-13'>.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></p>
      <p class='class-one'><span class='font-size-13'>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&apos;DNT&apos;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>10. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><em><span class='font-size-13'>In Short:</span></em></strong><em><span class='font-size-13'>&nbsp;Yes. we will update this notice as necessary to stay compliant with relevant laws.</span></em></p>
      <p class='class-one'><em><span class='font-size-13'>&nbsp;</span></em></p>
      <p class='class-one'><span class='font-size-13'>We may update this privacy notice from time to time. The updated version will be indicated by an updated &apos;Revised&apos; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>If you have questions or comments about this notice, you may email us at&nbsp;</span><a href="mailto:admin@clubinweb.in"><span class='font-size-13'>admin@clubinweb.in</span></a><span class='font-size-13'>&nbsp;or by post to:</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>CLUBIN PLATFORMS PRIVATE LIMITED</span></p>
      <p class='class-one'><span class='font-size-13'>Prithvi 406, My home Navadweepa</span></p>
      <p class='class-one'><span class='font-size-13'>Near Hitech Citv. Madhapur&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>Hyderabad, Telangana 500081</span></p>
      <p class='class-one'><span class='font-size-13'>India</span></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><strong><span class='font-size-16'>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></p>
      <p class='class-one'><span class='font-size-13'>&nbsp;</span></p>
      <p class='class-one'><span class='font-size-13'>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete our personal information please submit a request form and mail it to us.</span></p>
      <br />
      <br />
      <br />
      <br />
    </div>
    {/* <!-- scroll to top --> */}

    <div class="progress-wrap">
      <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
                                    
  </>
);};

export default PrivacyPolicy;