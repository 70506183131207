import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import '../css/feedback.css';

const Feedback = () => {

  const navigate = useNavigate();

  const [BuzDetails, setBuzDetails] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [description, setDescription] = useState('');
  const [rating, setCurrentValue] = useState();
  const [error, setError] = useState('');
  const [ratingError, setRatingError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleRadioChange = (e) => {
    const rateVal = e.target.value;
    setCurrentValue(rateVal);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!isEmailValid(value)) {
      setError('Invalid email format.');
    } else {
      setError('');
    }
  };

  // Validation logic for email format
  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get('code');

  useEffect(() => {
    fetch(`/api/qrcode/getBuzDetailsById/${code}`)
      .then((response) => response.json())
      .then((data) => {

        setBuzDetails(data.data);

      });

  }, []);




  let handleSubmit = async (e) => {
    e.preventDefault();


    if (!rating) {
      setRatingError('Rating Required.');
    } else if (!email) {
      setEmailError('Email Required.');
    }

    if (!error && ratingError && emailError) {
      try {
        const response = await fetch("/qrcode/feedback", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: code,
            rating_one: rating,
            commentText: description,
            name: name,
            email: email,
            phone: mobileNumber
          }),
        });
        console.log(response);
        if (response.status === 200) {

          navigate('/SuccessPage', { state: BuzDetails }) // Redirect to new page
        } else {

        }
      } catch (err) {
        console.log(err);
      }

    }
  };

  return (
    <>

      <div class="business-feedback">
        <span><h4>Business Ratings </h4><br /></span>

        <section>
          <div class="rt-container">
            <div class="col-rt-12">
              <div class="feedback">
                <p class="txtclr">
                  Dear Customer,
                  <br /><br />
                  Thank you for visiting <u><b>{BuzDetails.businessName}</b>,</u> our <u><b>{BuzDetails.clubName}</b></u> Business. We appreciate your patronage.
                  <br />
                  Please take a moment to tell us how we did and/or how we can improve!
                  <br />
                  <br />
                  Required fields are marked with *
                </p>
                <form onSubmit={handleSubmit}>

                  <div><label class="txtclr">Overall Rating*</label>
                    <br />

                    <span class="star-rating" >
                      <input type="radio" formControlName="rating_one" name="rating" value="1" onChange={handleRadioChange} />
                      <i></i>
                      <input type="radio" formControlName="rating_one" name="rating" value="2" onChange={handleRadioChange} />
                      <i></i>
                      <input type="radio" formControlName="rating_one" name="rating" value="3" onChange={handleRadioChange} />
                      <i></i>
                      <input type="radio" formControlName="rating_one" name="rating" value="4" onChange={handleRadioChange} />
                      <i></i>
                      <input type="radio" formControlName="rating_one" name="rating" value="5" onChange={handleRadioChange} />
                      <i></i>

                    </span>
                    {ratingError && <p>{ratingError}</p>}
                  </div>

                  <div class="clear"></div>
                  <label for="commentText" class="txtclr">Review Comments</label>
                  <br />

                  <textarea cols="60" formControlName="commentText" name="description" value={description} placeholder="Comments" rows="5" onChange={(e) => setDescription(e.target.value)}></textarea>

                  <div class="form-group row" style={{ marginTop: '15px' }}>
                    <label for="Name" class="col-sm-2 col-form-label">Your Name</label>
                    <div class="col-sm-10">
                      <input type="text" formControlName="name" name="name" class="input-cls" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="email" class="col-sm-2 col-form-label">Email ID*</label>
                    <div class="col-sm-10">
                      <input type="email" formControlName="email" name="email" class="input-cls" value={email} placeholder="Email" onChange={handleEmailChange} />
                      {emailError && <p>{emailError}</p>}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phone" class="col-sm-2 col-form-label">Mobile No</label>
                    <div class="col-sm-10">
                      <input type="number" formControlName="phone" name="mobileNumber" value={mobileNumber} placeholder="Mobile Number" onChange={(e) => setMobileNumber(e.target.value)} class="input-cls" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phone" class="col-sm-2 col-form-label" >{error && <p>{error}</p>}</label>
                    <div class="col-sm-10">
                      <input class="btn btn-primary btn-lg" style={{ color: '#fff', backgroundColor: '#E02473' }} type="submit" value="Submit Rating" />&nbsp;
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- scroll to top --> */}

      <div class="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

    </>
  );
};

export default Feedback;